import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Flex,
    Heading,
    Button, useColorModeValue
} from "@chakra-ui/react";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "./firebase";
import toast, { Toaster } from 'react-hot-toast';
import {ALLOWED_WRITE_EMAILS} from "./Allowed_emails.js";
import {FcGoogle} from "react-icons/fc";
const Signin = () => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const provider = new GoogleAuthProvider();
    const allowedEmails = ALLOWED_WRITE_EMAILS
    const signInWithGoogle = async () => {
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            const email = user.email;
            if (allowedEmails.includes(email)) {
                setIsLoading(true);
                const alltecchRes = await fetch("https://alltech.gachara.store/api/token/", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ username: process.env.REACT_APP_USERNAME, password: process.env.REACT_APP_ALLTECH }),
                });
                const alltecchData = await alltecchRes.json();
                localStorage.setItem("access", alltecchData.access);
                navigate('/')
            } else {
                await auth.signOut();
                toast.error("Your email is not allowed to sign in.");
            }
        } catch (error) {
            console.error("Error during sign-in:", error);
            toast.error("Error during Google Sign-In.");
        }
    };


    const bgColor = useColorModeValue('white', 'gray.800');
    const textColor = useColorModeValue('gray.800', 'white');

    return (
        <Flex
            justify="center"
            align="center"
            minH="100vh"
            bg={useColorModeValue('gray.50', 'gray.900')}
            position="relative"
            overflow="hidden"
        >
            <Toaster />
            <Box
                bg={bgColor}
                p={8}
                borderRadius="xl"
                boxShadow="2xl"
                maxW="400px"
                w="90%"
                backdropFilter="blur(10px)"
                border="1px solid"
                borderColor={useColorModeValue('gray.200', 'gray.700')}
                zIndex={1}
            >
                <Heading as="h2" size="2xl" textAlign="center" mb={8} color={textColor}>
                    ALLTECH SHOP 1
                </Heading>
                <Button
                    leftIcon={<FcGoogle />}
                    mt={4}
                    colorScheme="gray"
                    variant="outline"
                    width="full"
                    size="lg"
                    isLoading={isLoading}
                    loadingText="Signing in"
                    onClick={signInWithGoogle}
                    _hover={{ bg: useColorModeValue('gray.100', 'gray.700') }}
                >
                    Sign in with Google
                </Button>
            </Box>
        </Flex>
    );
};

export default Signin;
