import {useState, useEffect} from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
    Box,
    Flex,
    Input,
    Button,
    Heading,
    Text, Skeleton, SkeletonText, useToast,
} from "@chakra-ui/react";
import Navbar from "./Navbar";
import {get, push, ref, serverTimestamp, set, update} from "firebase/database";
import { database} from "./firebase.js";

import {client} from "./commonVariables";
import useAuth from "../customHooks/useAuth";
export default function Sell() {
    const [stock, setData] = useState(null);
    const [customer, setCustomer] = useState("");
    const navigate = useNavigate();
    const [send, setSend] = useState(false);
    const [sellingPrice, setSellingPrice] = useState(0.0);
    const {id} = useParams();
    const [completeB,setComplete] = useState(false)
    const productRef = ref(database, `alltech/LCD/${id}`);
    const savedRef = ref(database, 'alltech/Saved');
    const compleRef = ref(database, 'alltech/Complete');
    const receiptRef = ref(database, 'alltech/Receipt');
    const toast = useToast()
    useAuth()
    const complete = async (id) => {
        if(!validateForm()){
            return;
        }
        setComplete(true)
        const dataToSend = {
            product_name: stock.product_name,
            price: sellingPrice,
            quantity: 1,
            customer_name: customer,
            timestamp: serverTimestamp(),
            product_id : id
        };
        const updateQuantity = {
            product_name: stock.product_name,
            price: sellingPrice,
            quantity: (stock.quantity - 1),
            timestamp: serverTimestamp(),
        };

        await update(productRef,updateQuantity).then(()=>{
        }).catch(()=>{
            setComplete(false)
            toast.error("Error updating product");
        })
        const newPostRef = push(savedRef);
        await set(newPostRef, dataToSend).then(()=>{
        }).catch(()=>{
            setComplete(false)
            toast.error("Error updating product");
        })
        const updateProduct = await client.index('LCD').updateDocuments([{
            id:id,
            product_name:stock.product_name,
            quantity : (stock.quantity - 1),
            price : stock.price,
        }])
        if(updateProduct.status === "enqueued"){
            navigate('/');
        }
        else {
            setComplete(false)
            toast.error("Unable to update prouct")
        }
    };
    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const snapshot = await get(productRef);
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    if(data.quantity === 0 || data.quantity === ""){
                        navigate('/')
                    }
                    setData(data);
                    setSellingPrice(data.price)
                } else {
                    toast.error('unable to find item')
                    console.log("No such product exists!");
                    // Handle case where product doesn't exist
                }
            } catch (error) {
                console.error("Error fetching product:", error.message);
                toast.error(error.message);
            }
        };

        fetchProduct();

        // Clean up function if needed (unsubscribe from snapshot or clear state)
        // For example:
        // return () => { /* cleanup code */ };

    }, []);
    const validateForm = () => {
        if (!stock.product_name.trim()) {
            toast.error("Product name is required");
            return false;
        }
        if (isNaN(stock.quantity) || stock.quantity < 0) {
            toast.error("Quantity must be a positive number");
            return false;
        }
        if (isNaN(sellingPrice) || sellingPrice < 0) {
            toast.error("Price must be a positive number");
            return false;
        }
        if (!customer.trim() ||  !/^[a-zA-Z\s]+$/.test(customer)) {
            toast.error("Customer name is required");
            return false;
        }
        return true;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const dataToSend = {
            product_name: stock.product_name,
            price: sellingPrice,
            quantity: 1,
            customer_name: customer,
            timestamp: serverTimestamp(),
            product_id : id
        };
        const updateQuantity = {
            product_name: stock.product_name,
            price: sellingPrice,
            quantity: (stock.quantity - 1),
            timestamp: serverTimestamp(),
        };
        if(!validateForm()){
            return;
        }
        setSend(true);
        await update(productRef,updateQuantity).then(()=>{
        }).catch(()=>{
            setSend(false)
            toast({
                status:'error',
                description:'Error updating product',
            })
        })
        const newPostRef = push(compleRef);
        const newReceipt = push(receiptRef)
        await set(newPostRef, dataToSend).then(()=>{
        }).catch(()=>{
            toast({
                status:'error',
                description:'Error updating product',
            })
        })
        await set(newReceipt, dataToSend).then(()=>{
        }).catch(()=>{
            toast({
                status:'error',
                description:'Error updating product',
            })
        })
        const updateProduct = await client.index('LCD').updateDocuments([{
            id:id,
            product_name:stock.product_name,
            quantity : (stock.quantity - 1),
            price : stock.price,
        }])
        if(updateProduct.status === "enqueued"){
            toast({
                status:'success',
                description:'Item sold successfully',
            })
            navigate('/');
        }
        else {

            toast({
                status:'error',
                description:'Error updating product',
            })
        }
    };

    function handleDecimalsOnValue(value) {
        const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
        return value.match(regex)[0];
    }

    function checkValue(event) {
        setSellingPrice(handleDecimalsOnValue(event.target.value));
    }

    return (
        <div>
            <Navbar />
            <Box mt={20}>
                <Heading as="h2" size="xl" textAlign="center" color="black" mb={4}>
                           Sell LCD/TOUCH
                        </Heading>
                {stock ? (
                    <Flex justify="center" alignItems="center" direction="column">
                        <Box
                            as="form"
                            onSubmit={handleSubmit}
                            maxW={{base: "90%", md: "md"}}
                            width="full"
                            px={4}
                        >
                            <Text   color="black" mb={2}>Product</Text>
                            <Input  color="black" value={stock.product_name} isReadOnly mb={4}/>
                            <Text   color="black" mb={2}>Quantity</Text>
                            <Input   color="black" value={1} isReadOnly mb={4}/>
                            <Text   color="black" mb={2}>Selling Price</Text>
                            <Input
                                required={true}
                                value={sellingPrice}
                                color="black"
                                type="number"
                                onChange={(event) => checkValue(event)}
                                mb={4}
                            />
                            <Text   color="black" mb={2}>Customer</Text>
                            <Input
                                value={customer}
                                color="black"
                                required={true}
                                type="text"
                                onChange={(e) => setCustomer(e.target.value)}
                                mb={4}
                            />
                            <Flex justify="center">
                                <Button
                                    colorScheme="green"
                                    variant="outline"
                                    isDisabled={send}
                                    isLoading={completeB}
                                    loadingText="Saving"
                                    onClick={() => complete(id)}
                                    width="full"
                                    mr={2}
                                >
                                    Save
                                </Button>
                                <Button
                                    colorScheme="blue"
                                    isLoading={send}
                                    isDisabled={completeB}
                                    loadingText="Completing"
                                    width="full"
                                    type="submit"
                                    ml={2}
                                >
                                    Complete
                                </Button>
                            </Flex>
                        </Box>
                    </Flex>
                ) : (
                    <Flex justify="center" alignItems="center" direction="column">
                        <Box maxW={{base: "90%", md: "md"}} width="full" px={4}>
                            <SkeletonText mt="4" noOfLines={1} spacing="4" skeletonHeight="8" />
                            <Skeleton height="40px" mt="4" />
                            <SkeletonText mt="4" noOfLines={1} spacing="4" skeletonHeight="8" />
                            <Skeleton height="40px" mt="4" />
                            <SkeletonText mt="4" noOfLines={1} spacing="4" skeletonHeight="8" />
                            <Skeleton height="40px" mt="4" />
                            <SkeletonText mt="4" noOfLines={1} spacing="4" skeletonHeight="8" />
                            <Skeleton height="40px" mt="4" />
                            <Flex justify="center" mt="4">
                                <Skeleton height="40px" width="50%" mr={2} />
                                <Skeleton height="40px" width="50%" ml={2} />
                            </Flex>
                        </Box>
                    </Flex>
                )}
            </Box>
        </div>
    );
}