import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
    Box,
    Flex,
    Input,
    Button,
 FormControl, FormLabel, Heading, SkeletonText, Skeleton,
} from "@chakra-ui/react";
import Navbar from "./Navbar";
import  {toast, Toaster } from 'react-hot-toast';
import { database} from "./firebase.js";
import {get, ref,update} from "firebase/database";
import useAuth from "../customHooks/useAuth";
import {client} from "./commonVariables";

export default function UpdateLcd() {
    const { id } = useParams();
    const [data, setData] = useState(null);
    const navigate = useNavigate();
    const [sending, setSending] = useState(false);
    const productRef = ref(database, `alltech/LCD/${id}`);
useAuth()
    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const snapshot = await get(productRef);
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    setData(data);

                } else {
                    toast.error('unable to find item')
                    console.log("No such product exists!");
                    // Handle case where product doesn't exist
                }
            } catch (error) {
                console.error("Error fetching product:", error.message);
               toast.error(error.message);
            }
        };

        fetchProduct();

        // Clean up function if needed (unsubscribe from snapshot or clear state)
        // For example:
        // return () => { /* cleanup code */ };

    }, [id]);
    const handleChange = (event) => {
        const { name, value } = event.target;
        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const validateForm = () => {
        if (!data.product_name.trim()) {
            toast.error("Product name is required");
            return false;
        }
        if (isNaN(data.quantity) || data.quantity < 0) {
            toast.error("Quantity must be a positive number");
            return false;
        }
        if (isNaN(data.price) || data.price <= 0) {
            toast.error("Price must be a positive number");
            return false;
        }
        return true;
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        setSending(true)
        if(!validateForm()) {
            setSending(false)
            return;
        }
        try {
            await update(productRef,data).then(()=>{
            }).catch(()=>{
                toast.error("Error updating product");
            })
            const updateProduct = await client.index('LCD').updateDocuments([{
                id:id,
                product_name:data.product_name,
                quantity : data.quantity,
                price : data.price,
            }])
            if(updateProduct.status === "enqueued"){
                navigate('/');
            }
            else {
                setSending(false)
                toast.error("Unable to update prouct")
            }
        }catch (err){
            toast.error(err.message);
        }finally {

            setSending(false)
        }
    };

    return (
        <div>
            <Navbar />
            <Box mt={20}>
                <Toaster />

                {data ? <Flex
                    justifyContent="center"
                    alignItems="center"
                    minH="80vh"
                    //bg={useColorModeValue("gray.100", "gray.900")}
                    px={{base: 4, md: 8}}
                    py={{base: 4, md: 8}}
                >
                    <Box
                        bg={"white"}
                        p={{base: 4, md: 8}}
                        borderRadius="md"
                        boxShadow="lg"
                        maxW={{base: "full", sm: "md"}}
                        width="100%"
                    >
                        <form onSubmit={handleSubmit}>
                            <Heading as="h2" size="xl" textAlign="center" color="black" mb={4}>
                                Update LCD/TOUCH
                            </Heading>
                            <FormControl id="product_name" mb={4}>
                                <FormLabel>Product Name</FormLabel>
                                <Input
                                    type="text"
                                    required={true}
                                    color="black"
                                    value={data?.product_name || ""}
                                    onChange={handleChange}
                                    name="product_name"
                                />
                            </FormControl>
                            <FormControl id="quantity" mb={4}>
                                <FormLabel>Quantity</FormLabel>
                                <Input
                                    type="number"
                                    required={true}
                                    value={data?.quantity || ""}
                                    onChange={handleChange}
                                    color="black"
                                    name="quantity"
                                />
                            </FormControl>
                            <FormControl id="price" mb={6}>
                                <FormLabel>Selling Price</FormLabel>
                                <Input
                                    type="number"
                                    required={true}
                                    value={data?.price || ""}
                                    onChange={handleChange}
                                    color="black"
                                    name="price"
                                />
                            </FormControl>
                            <Button
                                type="submit"
                                colorScheme="blue"
                                isLoading={sending}
                                loadingText="Saving..."
                                width="100%"
                            >
                                Save
                            </Button>
                        </form>
                    </Box>
                </Flex> :(
                    <Flex justify="center" alignItems="center" direction="column">
                        <Box maxW={{base: "90%", md: "md"}} width="full" px={4}>
                            <SkeletonText mt="4" noOfLines={1} spacing="4" skeletonHeight="8" />
                            <Skeleton height="40px" mt="4" />
                            <SkeletonText mt="4" noOfLines={1} spacing="4" skeletonHeight="8" />
                            <Skeleton height="40px" mt="4" />
                            <SkeletonText mt="4" noOfLines={1} spacing="4" skeletonHeight="8" />
                            <Skeleton height="40px" mt="4" />
                            <SkeletonText mt="4" noOfLines={1} spacing="4" skeletonHeight="8" />
                            <Skeleton height="40px" mt="4" />
                            <Flex justify="center" mt="4">
                                <Skeleton height="40px" width="50%" mr={2} />
                                <Skeleton height="40px" width="50%" ml={2} />
                            </Flex>
                        </Box>
                    </Flex>
                )}
            </Box>
        </div>
    );
}