import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Signin from "./components/Signin.jsx";
import AddLcd from "./components/AddLcd.jsx";
import ItemList from "./components/ItemList.jsx";
import UpdateLcd from "./components/UpdateLcd.jsx";
import Sell from "./components/Sell.jsx";
import AddAccessory from "./components/AddAccessory.jsx";
import Accessories from "./components/Accessories.jsx";
import UpdateAccessories from "./components/UpdateAccessories.jsx";
import SellAccessory from "./components/SellAccessory.jsx";
import Saved from "./components/Saved.jsx";
import Dashboard from "./components/Dashboard";
import DetailedDataView from "./components/DetailedDataView";
function App() {

  return (

      <Router>
        <Routes>
          <Route path="/Login" element={<Signin />} />
          <Route path="/AddLcd" element={<AddLcd />} />
          <Route path="/" element={<ItemList />} />
          <Route path="/UpdateLcd/:id" element={<UpdateLcd />} />
          <Route path="/SellLcd/:id" element={<Sell />} />
          <Route path="/AddAccessory" element={<AddAccessory />} />
          <Route path="/Accessories" element={<Accessories />} />
          <Route path="/UpdateAccessory/:id" element={<UpdateAccessories />} />
          <Route path="/SellAccessory/:id" element={<SellAccessory />} />
          <Route path="/Transactions" element={<Saved />} />
            <Route path="/Admin" element={<Dashboard />} />
          <Route path="/detailed" element={<DetailedDataView />} />
        </Routes>
      </Router>
  )
}

export default App
