import {useEffect} from "react";
import {onAuthStateChanged} from "firebase/auth";
import {auth} from "../components/firebase";
import toast from "react-hot-toast";
import {ALLOWED_WRITE_EMAILS} from "../components/Allowed_emails";
import {useNavigate} from "react-router-dom";

export default function useAuth() {
    const navigate = useNavigate()
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (!currentUser) {
                toast.error("You must be logged in to access this page");
                navigate('/Login');
            } else if (!ALLOWED_WRITE_EMAILS.includes(currentUser.email)) {
                toast.error("You don't have permission to access this page");
                navigate('/Login');
            }
        });
        return () => unsubscribe();
    }, [navigate]);
}