import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
    Box,
    Flex,
    Input,
    Button,
    Heading,
    Text,
   SkeletonText, Skeleton,
} from "@chakra-ui/react";
import Navbar from "./Navbar";
import { toast, Toaster } from 'react-hot-toast';
import { get, push, ref, serverTimestamp, set, update } from "firebase/database";
import {  database } from "./firebase.js";
import useAuth from "../customHooks/useAuth";
import {client} from "./commonVariables";
export default function SellAccessory() {
    const [stock, setData] = useState(null);
    const [customer, setCustomer] = useState("");
    const navigate = useNavigate();
    const [send, setSend] = useState(false);
    const [sellingPrice, setSellingPrice] = useState(0.0);
    const [sellingQuantity, setSellingQuantity] = useState(0);
    const { id } = useParams();
    const [completeB, setComplete] = useState(false);
    const productRef = ref(database, `alltech/Accessory/${id}`);
    const completeRef = ref(database, 'alltech/CompleteAccessory');
    const receiptRef = ref(database, 'alltech/ReceiptAccessory');

 useAuth()

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const snapshot = await get(productRef);
                if (snapshot.exists()) {
                    const productData = snapshot.val();

                    if (productData.quantity <= 0 || productData.quantity === "" || !productData.quantity) {
                        navigate('/Accessories');
                    }
                    setData(productData);
                    setSellingPrice(productData.price)
                } else {
                    toast.error('Unable to find item');
                    console.log("No such product exists!");
                }
            } catch (error) {
                console.error("Error fetching product:", error.message);
                toast.error(error.message);
            }
        };

        fetchProduct();
    }, [navigate, productRef]);

    const validateForm = () => {
        if (!stock.product_name.trim()) {
            toast.error("Product name is required");
            return false;
        }
        if (isNaN(sellingQuantity) || sellingQuantity <= 0) {
            toast.error("Quantity must be a positive number");
            return false;
        }
        if (sellingQuantity > stock.quantity) {
            toast.error("Quantity in stock is insufficient");
            return false;
        }
        if (isNaN(sellingPrice) || sellingPrice <= 0) {
            toast.error("Price must be a positive number");
            return false;
        }
        if (!customer.trim() ||  !/^[a-zA-Z\s]+$/.test(customer)) {
            toast.error("Customer name is required");
            return false;
        }
        return true;
    };

    const complete = async () => {
        if (!validateForm()) {
            return;
        }
        setComplete(true);
        const dataToSend = {
            product_name: stock.product_name,
            price: sellingPrice * sellingQuantity,
            quantity: sellingQuantity,
            customer_name: customer,
            timestamp: serverTimestamp(),
            product_id: id
        };

        const updateQuantity = {
            product_name: stock.product_name,
            price: stock.price, // Maintain the original price
            quantity: stock.quantity - sellingQuantity,
            timestamp: serverTimestamp(),
        };

        try {
            await update(productRef, updateQuantity);
            const newPostRef = push(completeRef);
            const receipt = push(receiptRef);
            await set(newPostRef, dataToSend);
            await set(receipt, dataToSend);

            const updateProduct = await client.index('Shop1Accessory').updateDocuments([{
                id: id,
                product_name: stock.product_name,
                quantity: stock.quantity - sellingQuantity,
                price: stock.price,
            }]);

            if (updateProduct.status === "enqueued") {
                navigate('/Accessories');
            } else {
                setComplete(false);
                toast.error("Unable to update product");
            }
        } catch (error) {
            setComplete(false);
            toast.error("Error updating product");
            console.error("Error updating product:", error.message);
        }
    };

    function handleDecimalsOnValue(value) {
        const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
        return value.match(regex)[0];
    }

    function checkValue(event) {
        setSellingPrice(handleDecimalsOnValue(event.target.value));
    }

    return (
        <div>
            <Navbar />
            <Box mt={20}>
                <Toaster />
                <Heading as="h2" size="xl" textAlign="center" color="black" mb={4}>
                            Sell Accessory
                        </Heading>
                {stock ? (
                    <Flex justify="center" alignItems="center" direction="column">
                        <Box
                            as="form"
                            maxW={{ base: "90%", md: "md" }}
                            width="full"
                            px={4}
                        >
                            <Text color="black" mb={2}>Product</Text>
                            <Input color="black" value={stock.product_name} isReadOnly mb={4} />
                            <Text color="black" mb={2}>Quantity</Text>
                            <Input
                                required
                                color="black"
                                value={sellingQuantity}
                                type="number"
                                onChange={(e) => setSellingQuantity(parseInt(e.target.value))}
                                mb={4}
                            />
                            <Text color="black" mb={2}>Selling Price</Text>
                            <Input
                                required={true}
                                value={sellingPrice}
                                color="black"
                                type="number"
                                onChange={checkValue}
                                mb={4}
                            />
                            <Text color="black" mb={2}>Customer</Text>
                            <Input
                                value={customer}
                                color="black"
                                required
                                type="text"
                                onChange={(e) => setCustomer(e.target.value)}
                                mb={4}
                            />
                            <Flex justify="center">
                                <Button
                                    colorScheme="green"
                                    variant="outline"
                                    type="button"
                                    isDisabled={send}
                                    isLoading={completeB}
                                    loadingText="Completing..."
                                    onClick={complete}
                                    width="full"
                                    ml={2}
                                >
                                    Sell
                                </Button>
                            </Flex>
                        </Box>
                    </Flex>
                ) : (
                    <Flex justify="center" alignItems="center" direction="column">
                        <Box maxW={{base: "90%", md: "md"}} width="full" px={4}>
                            <SkeletonText mt="4" noOfLines={1} spacing="4" skeletonHeight="8" />
                            <Skeleton height="40px" mt="4" />
                            <SkeletonText mt="4" noOfLines={1} spacing="4" skeletonHeight="8" />
                            <Skeleton height="40px" mt="4" />
                            <SkeletonText mt="4" noOfLines={1} spacing="4" skeletonHeight="8" />
                            <Skeleton height="40px" mt="4" />
                            <SkeletonText mt="4" noOfLines={1} spacing="4" skeletonHeight="8" />
                            <Skeleton height="40px" mt="4" />
                            <Flex justify="center" mt="4">
                                <Skeleton height="40px" width="50%" mr={2} />
                                <Skeleton height="40px" width="50%" ml={2} />
                            </Flex>
                        </Box>
                    </Flex>
                )}
            </Box>
        </div>
    );
}
