import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getAuth } from "firebase/auth";
import {getDatabase} from "firebase/database";
const firebaseConfig = {
    apiKey: "AIzaSyCZYwIsOcXDZn94_nMFjFv_RCqiZBGi-UQ",
    authDomain: "alltech-d141a.firebaseapp.com",
    databaseURL: "https://alltech-d141a-default-rtdb.firebaseio.com",
    projectId: "alltech-d141a",
    storageBucket: "alltech-d141a.appspot.com",
    messagingSenderId: "418467645359",
    appId: "1:418467645359:web:af0765c8b6c7d30527d15a",
    measurementId: "G-ESWB85JD4R",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const auth = getAuth(app);
const database = getDatabase();
 const requestForToken = () => {
    return getToken(messaging, {
        vapidKey:
            "BETcPOCFgcaYlMGqJ7D_5FIf1dz-fGbTEa5rx9kdvqHSgZu83HQMfS9hMHlfOZDQyc25GK-isOtrVIlE08H2f8I",
    })
        .then((currentToken) => {
            if (currentToken) {
                console.log("current token for client: ", currentToken);
            } else {
                console.log(
                    "No registration token available. Request permission to generate one.",
                );
            }
        })
        .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
        });
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });
export  {messaging,auth,database,requestForToken};
